import React from 'react';
import subscribeArrow from '@/images/subscribe-arrow.svg';
import './index.css';

export default function VitractUniversity() {
  function handleOpen(url: string) {
    window.open(url);
  }

  return (
    <div className="w-full h-full mt-[120px] xl:mt-[105px]">
      <div className="bg-university-header-background bg-[#19233F]">
        <div className="flex flex-col w-full h-full md:max-w-[599px] xlg:max-w-[697px] justify-center items-center mx-auto md:px-0 py-[159px] px-[19.5px] md:py-[250.5px]">
          <h1 className="text-[32px] md:text-[40px] lg:text-6xl text-[#FFF] text-center font-bold">
            Become a Gut <br /> Microbiome{` `}
            <span className="italic text-[#FF713F] font-bold">expert</span>
          </h1>
          <p className="w-full text-[#FFF] pt-6 flex text-center">
            Learn how to understand, assess, and optimize gut health so you can
            become a trusted expert people turn to for advice, making a real
            difference in people's lives.
          </p>
          <div className="flex flex-col gap-[10px] mt-6">
            <button
              type="button"
              onClick={() => handleOpen(`https://vitractu.circle.so/`)}
              className="bg-[#FF713F] hover:bg-inherit border border-[#FF713F] py-[14px] px-[14px] rounded  text-[#fff] hover:text-[#FF713F] font-medium items-center flex justify-center cursor-pointer"
            >
              Enter Community
            </button>
            <div className="flex items-center">
              <div className="flex py-[14px] px-[14px] gap-1">
                <span className="text-white text-lg">Not a member?</span>
                <a
                  className="text-[#FF713F] text-lg decoration-none font-bold"
                  href="#price-cards"
                >
                  Subscribe
                </a>
              </div>
              <img
                src={subscribeArrow}
                alt="subscribe arrow"
                className="mt-[24px]"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Body Content */}
      <div className="flex flex-col w-full h-full xl:w-[1204px] justify-center items-center mx-auto px-5 py-[120px] text-[#19233F]">
        <div className="flex flex-col gap-y-6 justify-center items-center">
          <p className="w-[259px] md:w-full text-[#19233F] text-center font-bold text-2xl md:text-[32x] slg:text-[40px]">
            Pay Less, Learn More From The Best
          </p>
        </div>
        <div className="w-full h-full gap-8 grid grid-cols-1 slg:grid-cols-3 pt-14 md:pt-[72px] md:px-[163px] slg:px-0">
          <div className="h-[730px] xsm:h-[550px] slg:h-full bg-transparent rounded-[20px] flip-card">
            <div className="relative h-full w-full flip-card-inner">
              <div className="absolute w-full h-full flip-card-front bg-chidozie-background bg-cover bg-center bg-no-repeat rounded-[20px] flex flex-col justify-end pl-6 pb-[39px] md:pb-12">
                <h1 className="text-xl md:text-2xl text-[#FFF] font-bold">
                  Chidozie Ojobor, Ph.D
                </h1>
                <span className="text-xs md:text-sm text-[#FFF] font-medium">
                  Co-Founder and Chief Scientific Officer
                </span>
              </div>

              <div className="p-6 absolute w-full h-full flip-card-back bg-university-card-background bg-[#19233F] text-[#FFF] rounded-[20px]">
                <h1 className="pb-6 text-2xl font-bold">BIO</h1>
                <div className="flex flex-col gap-y-6 md:gap-y-8 text-base font-medium">
                  <p>
                    Dr. Ojobor is a scientist with over 15 years+ research
                    experience in bacterial genetics. He has a Bachelors in
                    Microbiology, and obtained his Ph.D. in Molecular Genetics
                    from the University of Toronto.
                  </p>

                  <p>
                    Dr. Ojobor has spent a decade developing novel antimicrobial
                    entities that target disease-causing bacteria in complex
                    biological ecosystems such as the gut. In 2019, he won the
                    Global citizen award for combating drug-resistant bacteria
                    implicated in exacerbating cystic fibrosis, with the novel
                    bacteriocins he developed in the lab.
                  </p>
                  <p>
                    Dr. Ojobor’s previous research experience includes phage
                    therapy, CRISPR technology and gastroenteritis of viral
                    origin in children.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            id="price-cards"
            className="flex flex-col border-[1.5px] border-solid border-[#19233F] text-[#19233F] rounded-[20px] p-8 gap-y-8"
          >
            <h3 className="text-xs md:text-sm font-medium flex items-center">
              BASIC
            </h3>

            <div className="flex flex-col gap-y-8">
              <div className="flex">
                <div className="flex pr-2">
                  <h3 className="text-3xl font-bold">$39</h3>
                  <span className="text-[#A3A1A8] text-base font-medium pt-3">
                    /month
                  </span>
                </div>
              </div>
              <p>
                Try for 30 days and then switch to our monthly plan! Cancel
                anytime.
              </p>
              <button
                type="button"
                onClick={() =>
                  handleOpen(
                    `https://vitractu.circle.so/checkout/vitractu-basic-membership`,
                  )
                }
                className="w-full bg-inherit hover:bg-[#FF713F] border border-[#FF713F] py-3 px-6 rounded  text-[#FF713F] hover:text-[#fff] font-medium item-center flex justify-center cursor-pointer"
              >
                Subscribe
              </button>
            </div>
            <div className="border border-dashed border-[#C2C2C2]" />

            <div className="flex flex-col gap-y-6">
              <div className="flex gap-x-[14px] items-start">
                <img src="/images/tick.svg" alt="tick" className="py-2" />
                <p>
                  Pre-recorded modules on various topics (e.g., Composition and
                  diversity of the gut microbiome, Impact of diet on gut
                  microbiome, etc.)
                </p>
              </div>
              <div className="flex gap-x-[14px] items-start">
                <img src="/images/tick.svg" alt="tick" className="py-2" />
                <p>Additional offline educational resources</p>
              </div>
              <div className="flex gap-x-[14px] items-start">
                <img src="/images/tick.svg" alt="tick" className="py-2" />
                <p>Access anywhere at any time</p>
              </div>
              <div className="flex gap-x-[14px] items-start">
                <img src="/images/tick.svg" alt="tick" className="py-2" />
                <p>
                  New modules to help you stay updated with the ever-evolving
                  field
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col bg-university-card-background bg-[#19233F] rounded-[20px] text-[#FFF] p-8">
            <div className="flex gap-x-3">
              <h3 className="text-xs md:text-sm font-medium flex items-center">
                PREMIUM
              </h3>
              <span className="w-auto p-2 md:px-3 md:py-2 bg-[#1E9E10] rounded-3xl">
                Best offer
              </span>
            </div>
            <div className="flex flex-col pt-6 gap-y-8">
              <div className="flex">
                <div className="flex pr-2">
                  <h3 className="text-3xl font-bold">$79</h3>
                  <span className="text-[#A3A1A8] text-base font-medium pt-3">
                    /month
                  </span>
                </div>
              </div>
              <p>
                Try for 30 days and then switch to our monthly plan! Cancel
                anytime.
              </p>
              <button
                type="button"
                onClick={() =>
                  handleOpen(
                    `https://vitractu.circle.so/checkout/vitractu-premium-membership`,
                  )
                }
                className="w-full bg-[#FF713F] hover:bg-inherit border border-[#FF713F] py-3 px-6 rounded  text-[#fff] hover:text-[#FF713F] font-medium item-center flex justify-center cursor-pointer"
              >
                Subscribe
              </button>
            </div>
            <div className="border border-dashed border-[#C2C2C2] my-8" />

            <div className="flex flex-col gap-y-6">
              <h4>EVERYTHING IN BASIC PLUS:</h4>
              <div className="flex gap-x-[14px] items-start">
                <img src="/images/tick.svg" alt="tick" className="py-2" />

                <p>
                  Live session with Dr Chidozie to help you gain deeper insights
                  into your gut microbiome results once a month
                </p>
              </div>
              <div className="flex gap-x-[14px] items-start">
                <img src="/images/tick.svg" alt="tick" className="py-2" />
                <p>Get personalized answers to pre-submitted questions</p>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-8 md:pt-14 text-lg font-medium">
          While comparable education can cost $900+, we believe in making
          education accessible. We're keeping subscription costs low as you grow
          with us!
        </div>
      </div>
    </div>
  );
}
